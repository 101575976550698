import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage31() {
  const question = {
    title: 'Frage 31 von 35',
    headline: '',
    subline: 'Welche Größe sollte dein künftiges Team haben?',
  }

  const answers = [
    {
      name: 'teamgröße',
      id: '10',
      value: 'Bis zu 10 Mitarbeiter.',
      desc: '',
    },
    {
      name: 'teamgröße',
      id: '11-25',
      value: '11 - 25 Mitarbeiter.',
      desc: '',
    },
    {
      name: 'teamgröße',
      id: '26-50',
      value: '26 - 50 Mitarbeiter.',
      desc: '',
    },
    {
      name: 'teamgröße',
      id: '51',
      value: '51 Mitarbeiter oder größer.',
      desc: '',
    },
    {
      name: 'teamgröße',
      id: 'egal',
      value: 'Das ist mir egal.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-yellow-triangles">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="yellow"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="green"
              index={31}
            />
          </div>
        </section>

        <MatchingNavigation
          color="yellow"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-32`}
          prev={`${AREA_CARE_PATH}/spielen/frage-30`}
          index={31}
        />

        <Icon position="2" name="team-3" />
      </main>
    </>
  )
}
